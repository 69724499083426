const InitConnect = async (callback) => {
	try {
		// let result = await ethereum.request({
		// 	method: 'wallet_switchEthereumChain',
		// 	params: [{
		// 		chainId: '0x38'
		// 	}],
		// });
		let result = '';
		
		if (!result) {
			ethereum.request({
					method: 'eth_requestAccounts'
				})
				.catch(function(reason) {
					//如果用户拒绝了登录请求
					if (reason === "User rejected provider access") {
						// 用户拒绝登录后执行语句；
						alert("User rejected provider access");
					} else {
						// 本不该执行到这里，但是真到这里了，说明发生了意外
						alert("There was an issue signing you in.");
					}
				}).then(function(accounts) {
					callback(accounts[0]);
	
				});
		}
	} catch (switchError) {
		if (switchError.code === 4902) {
			try {
				await ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [{
						chainId: "0x38",
						chainName: "Binance Smart Chain",
						rpcUrls: [
							'https://bsc-dataseed.binance.org/',
						],
						iconUrls: [
							''
						],
						blockExplorerUrls: [
							'https://bscscan.com/'
						],
						nativeCurrency: {
							name: 'BNB',
							symbol: 'BNB',
							decimals: 18
						}
					}]
				});
			} catch (addError) {
				// handle "add" error
			}
		}
		// handle other "switch" errors
	}
}

export default {
	InitConnect
}
