<template>
	<div class="top-bar">
		<div class="icon-menu" @click="drawer = true">
			<img :src="require('../../assets/img/icon/icon_menu.png')" alt="">
		</div>
		<div style="display: flex;">
			<div class="connect-wallet" @click="connectMetamask()" v-if="!store.state.accountFm">
				{{store.state.lang.connectTxt}}
			</div>
			<div class="connect-wallet ls-flex-ac" v-else>
				<el-dropdown>
					<span class="el-dropdown-link">
						{{store.state.accountFm}}
					</span>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item>
								<el-button class="m-btn" @click="toPage('nft')">
									GO Mint
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
										class="bi bi-arrow-right-short" viewBox="0 0 16 16">
										<path fill-rule="evenodd"
											d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
								</el-button>
							</el-dropdown-item>
							<el-dropdown-item>
								<el-button class="m-btn" @click="toPage('bind')">
									BIND NFT
									<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
										class="bi bi-arrow-right-short" viewBox="0 0 16 16">
										<path fill-rule="evenodd"
											d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
								</el-button>
							</el-dropdown-item>
							<el-dropdown-item @click="disconnectWallet()">
								<el-button type="info" class="m-btn">DISCONNECT</el-button>
							</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<LangBtn></LangBtn>
			<!-- 链接钱包 -->
			<!-- <div class="connect-box" v-if="showConnectWallet">
				<div class="connect-item" @click="openConnectWallet(0)">
					<img :src="require('../../assets/img/icon_metamask.svg')" alt="">
					MetaMask
				</div>
				<div class="connect-item" @click="openConnectWallet(1)">
					<img :src="require('../../assets/img/icon_connnect.svg')" alt="">
					WalletConnect
				</div>
			</div> -->

		</div>
	</div>

	<el-drawer v-model="drawer" direction="ltr" :with-header="false" size="50%">
		<LeftMenu></LeftMenu>
	</el-drawer>
</template>

<script setup>
	import LeftMenu from '../left-menu/left-menu.vue';
	import LangBtn from '../lang-btn/lang-btn.vue';
	import {ArrowDown} from '@element-plus/icons-vue';
	import {ref,watch} from 'vue';
	import {useRouter} from 'vue-router';
	import {useStore} from 'vuex';
	import NCWeb3 from "@/store/initWeb3.js";
	import connect from "@/utils/formate.js";
	import {ElMessageBox} from 'element-plus'
	import Web3 from "web3";
	const store = useStore();
	const router = useRouter();
	const drawer = ref(false);
	const showConnectWallet = ref(false);
	const customPoper = ref("background: #800909!important;");

	const toPage = path => router.push(path);
	
	watch(()=>router.currentRoute.value.path,(path,toPath)=>{
		if(path!=toPath){
			drawer.value = false;
		}
	},{immediate:true,deep:true})
	
	const initConnectAccount = (addr) => {
		store.commit('initAccount', addr);
	};

	const connectMetamask = async() => {
		if (typeof window.ethereum === "undefined") {
			//没安装MetaMask钱包进行弹框提示
			ElMessageBox.alert(
				store.state.lang.metaMaskTips1,
				store.state.lang.warningTips, {
					dangerouslyUseHTMLString: true,
				}
			)
			ElMessageBox.alert(
				store.state.lang.metaMaskTips,
				store.state.lang.warningTips, {
					dangerouslyUseHTMLString: true,
				}
			)
		} else {
			NCWeb3.InitConnect(async addr => {
				if (addr) {
					let chainId = await ethereum.request({ method: 'eth_chainId' });
					store.commit('initChain', chainId);
					store.commit('initNet', 1);
					initConnectAccount(addr);
					showConnectWallet.value = false;
					window.location.reload();
				}
			})
		}
		
	}

	if (typeof window.ethereum === "undefined") {
		console.log('fail');
	} else {
		ethereum.on('accountsChanged', (accounts) => {
			if (store.state.account != accounts[0]) {
				initConnectAccount(accounts[0]);
				window.location.reload();
			}
		});

		ethereum.on('chainChanged', (_chainId) => {
			store.commit('initChain', _chainId);
			
			window.location.reload();
		});
	}
	

	const disconnectWallet = async () => {
		window.ethereum.on('disconnect', () => {});
		initConnectAccount('');
		localStorage.clear();
		store.commit('initChain', '');
		window.location.reload();
	}
</script>

<style scoped>
	.top-bar {
		position: sticky;
		top: 0;
		z-index: 999;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		height: 80px;
		width: 100%;
		padding: 0 30px;
		box-sizing: border-box;
		background: #800909;
	}

	.connect-wallet {
		height: 36px;
		padding: 0 15px;
		line-height: 36px;
		text-align: center;
		border-radius: 18px;
		cursor: pointer;
		border: 1px solid #FFFFFF;
		color: #fff;
	}

	.el-dropdown-link {
		color: #FFFFFF;
	}

	.icon-menu {
		width: 100px;
		height: 42px;
		cursor: pointer;
		display: none;
	}

	.icon-menu img {
		width: 42px;
	}

	.m-btn {
		width: 110px;
	}

	.poper-list {
		/* background: #800909!important;
		border: 1px solid #FFFFFF!important; */
	}

	.connect-box {
		position: fixed;
		right: 0;
		top: 80px;
		/* width:100px; */
		background-color: #800909;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}

	.connect-box .connect-item {
		padding: 15px 30px;
		box-sizing: border-box;
		cursor: pointer;
		font-size: 30px;
		font-weight: 500;
		color: #fff;
		border-top: 1px solid #260000;
	}

	.connect-box .connect-item img {
		width: 36px;
		border-radius: 10px;
	}

	@media (max-width:768px) {
		.top-bar {
			height: 60px;
			justify-content: space-between;
		}

		.icon-menu {
			display: block;
		}

		.connect-box {
			top: 60px;
		}

		.connect-box .connect-item {
			padding: 15px;
			font-size: 22px;
		}
	}
</style>
