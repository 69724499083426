<template>
	<div class="left-menu">
		<div class="text-center logo" @click="toPage('/')">
			<img :src="require('../../assets/img/icon/logo_menu.png')" alt="" class="user-img">			
		</div>
		<div class="ls-menu">
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_dash.png')" alt="">
					{{store.state.lang.dashboardText}}
				</a>
			</div>
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_pool.png')" alt="">
					{{store.state.lang.vcPoolText}}
				</a>
			</div>
			<div class="ls-menu-item">
				<router-link to="/bond">
					<img :src="require('../../assets/img/icon/icon_band.png')" alt="">
					{{store.state.lang.bondText}}
				</router-link>
			</div>
			<div class="ls-menu-item">
				<router-link to="/stake">
					<img :src="require('../../assets/img/icon/icon_stake.png')" alt="">
					{{store.state.lang.stackeText}}
				</router-link>
			</div>
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_rank.png')" alt="">
					{{store.state.lang.rankingText}}
				</a>
			</div>
			<div class="ls-menu-item">
				<router-link to="/nft">
					<img :src="require('../../assets/img/icon/logo_w.png')" alt="">
					{{store.state.lang.bullNftText}}
				</router-link>
			</div>
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_brige.png')" alt="">
					{{store.state.lang.birgeText}}
				</a>
			</div>
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_g.png')" alt="">
					{{store.state.lang.governanceText}}
				</a>
			</div>
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_doc.png')" alt="">
					{{store.state.lang.docsText}}
				</a>
			</div>
			<!-- <div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_faq.png')" alt="">
					{{store.state.lang.faqText}}
				</a>
			</div> -->
			<div class="ls-menu-item">
				<router-link to="/profile">
					<img :src="require('../../assets/img/icon/icon_profile.png')" alt="">
					{{store.state.lang.profileText}}
				</router-link>
			</div>
			<div class="ls-menu-item" @click="open">
				<a>
					<img :src="require('../../assets/img/icon/icon_guild.png')" alt="">
					{{store.state.lang.guidText}}
				</a>
			</div>
		</div>
		
		<div class="mune-footer is-h5-hide">
			<div class="ls-flex-ajc">
				<div class="ls-flex-1">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_1.png')" alt="">
					</a>
				</div>
				<div class="ls-flex-1">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_2.png')" alt="">
					</a>
				</div>
				<div class="ls-flex-1">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_3.png')" alt="">
					</a>
				</div>
			</div>
			<div class="ls-flex-ajc mt-2">
				<div class="ls-flex-1">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_4.png')" alt="">
					</a>
				</div>
				<div class="ls-flex-1">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_5.png')" alt="">
					</a>
				</div>
				<div class="ls-flex-1">
					<a class="ls-flex-ajc" @click="open()" target="_blank">
						<img :src="require('../../assets/img/icon/icon_6.png')" alt="">
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ElMessage } from 'element-plus';
	import {useRouter} from 'vue-router';
	import {useStore} from 'vuex';
	
	const store = useStore();
	
	const router = useRouter();
	
	const open = () => {
		ElMessage({
			message:store.state.lang.commingSoon,
			type:store.state.lang.warningTips
		});
	}
	
	const toPage = (path)=>router.push(path);
	
</script>

<style scoped>
	.left-menu {
		position: relative;
		height: 100vh;
		overflow-y: scroll;
		box-sizing: border-box;
		background-color: #260000;
	}
	.logo{
		padding: 20px 0;
		cursor:pointer;
	}
	.left-menu .user-img {
		width: 160px;
		height: auto;
	}
	.ls-menu{
		
	}
	.ls-menu .ls-menu-item{
		margin: 10px 0;
		padding: 0 20px;
		height: 40px;
		line-height: 40px;
		font-weight: 400;
		color: #666;
	}
	.ls-menu .bd2{
		border-top: 1px solid rgb(153, 167, 194);
		border-bottom: 1px solid rgb(153, 167, 194);
	}
	.ls-menu .ls-menu-item img{
		width: 18px;
		margin-right: 10px;
	}
	.ls-menu-item a{
		display: block;
		color: #e5e5e5;
		text-decoration: none;
		border-radius: 26px;
		padding: 0 15px;
		box-sizing: border-box;
		cursor:pointer;
	}
	.ls-menu-item a.router-link-active{
		color: #fff;
		background: #620000;
		border: 1px solid #FF0000;
	}
	.mune-footer{
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		padding:10px 30px;
		box-sizing: border-box;
	}
	.mune-footer a{
		width: 40px;
		height: 40px;
		background-color: #9F0E0E;
		border-radius: 50%;
	}
	.mune-footer a img{
		width: 20px;
	}
	@media (max-width:768px) {
		.ls-menu .ls-menu-item{
			margin: 10px 0;
			height: 30px;
		}
		.ls-menu-item a{
			font-size: 14px;
		}
		.is-h5-hide{
			display:none;
		}
	}
</style>
