<template>
	<div class="languge" @click="showLang=!showLang">
		<img :src="store.state.lang.langType=='EN'?require('../../assets/img/icon_en.png'):require('../../assets/img/icon_zh.png')" alt="">
	</div>
	<div class="lang-box" v-if="showLang">
		<div class="lang-item" @click="changeLang(0)">
			<img :src="require('../../assets/img/icon_en.png')" alt="">
		</div>
		<div class="lang-item" @click="changeLang(1)">
			<img :src="require('../../assets/img/icon_zh.png')" alt="">
		</div>
	</div>
</template>

<script setup>
	import {ref} from 'vue';
	import {useStore} from 'vuex';
	const store = useStore();
	
	const showLang = ref(false);
	
	const changeLang = (index)=>{
		store.commit('changeLang',index);
		showLang.value = false;
	}
</script>

<style scoped>
	.languge{
		overflow: hidden;
		width: 32px;
		height: 32px;
		border-radius: 5px;
		margin-left: 10px;
		cursor: pointer;
	}
	.languge img{
		width: 32px;
		height: 32px;
	}
	.lang-box{
		position: fixed;
		right: 0;
		top: 80px;
		width:100px;
		background-color: #800909;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
	}
	.lang-box .lang-item{
		padding: 15px;
		box-sizing: border-box;
		text-align: center;
		cursor: pointer;
	}
	.lang-box .lang-item img{
		width: 32px;
	}
	@media (max-width:768px) {
		.lang-box{
			top: 60px;
		}
	}
</style>